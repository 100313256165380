<template>
  <section class="actions-block-incident">
    <r-tooltip
      activator-hover
      color="amelie"
      @click.native.stop="returnSendIncident"
      title="Повторить отправку"
    >
      <template #activator>
        <r-icon
          class="actions-block-incident__action"
          icon="update"
          size="20"
          fill="rocky"
        />
      </template>
    </r-tooltip>
    <r-tooltip
      activator-hover
      class="ml-8"
      color="amelie"
      @click.native.stop="toComplete"
      title="Завершить без ответа"
    >
      <template #activator>
        <r-icon
          class="actions-block-incident__action"
          icon="selected"
          size="20"
          fill="rocky"
        />
      </template>
    </r-tooltip>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  name: 'ActionsBlockError',
  inject: ['incident'],
  computed: {
    ...mapState('session', ['user'])
  },
  methods: {
    ...mapActions('incident', ['moveIncident', 'getIncidents']),
    ...mapMutations('incident', ['setFilterProperty']),
    async performMoveIncident({ decision, success, error }) {
      await this.moveIncident([{
        id: this.incident.id,
        statusCode: this.incident.action,
        moderatorId: this.user.id,
        decision
      }])
        .then(() => {
          this.$rir.notification.send({
            title: success,
            seconds: 5
          });
        })
        .catch(() => {
          this.$rir.notification.send({
            title: error,
            seconds: 5
          });
        });
    },
    returnSendIncident() {
      this.performMoveIncident({
        decision: 'RETRY',
        success: 'Повторная попытка отправки',
        error: 'Ошибка'
      });
    },
    toComplete() {
      this.performMoveIncident({
        decision: 'COMPLETE_WITHOUT_RESPONSE',
        success: 'Обращение перенесено в "Решенные"',
        error: 'Не удалось перемеместить в "Решенные"'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.actions-block-incident {
  margin-left: auto;
  margin-right: 24px;
  display: flex;
  height: 100%;
  align-items: center;

  &__action {
    opacity: 0.72;
    transition: 0.3s;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
